const browserExtensionDenyList: RegExp[] = [
  /.*extensions\/.*/i,
  /.*chrome:\/\/.*/i,
  /.*safari-extension:\/\/.*/i,
  /.*safari-web-extension:\/\/.*/i,
  /.*chrome-extension:\/\/.*/i,
  /.*webkit-masked-url:.*/i,
];

export const commonClientIgnoreErrors: (RegExp | string)[] = [
  ...browserExtensionDenyList,
  // This ignores errors in global code. It's usually caused by browser extensions
  // or other third party scripts and has shown to not provide any valuable information to us.
  'top.GLOBALS',

  /**
   * This seems like a fullscreen video blocking mechanism. We don't have any videos,
   * and certainly don't run this code. Appears to happen exclusively on iOS with
   * Firefox v35 and v36. */
  "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",

  /**
   * These classes belong to the TineMCE WYSIWYG editor.
   * Only happens on mobile Safari. */
  "undefined is not an object (evaluating 'document.getElementsByClassName('hide-icon hidden mce-visual-caret-hidden')[0].remove')",

  /**
   * These are all errors from old browsers that don't consider "let" and "const"
   * stable features in strict mode. Can safely be ignored. */
  'Block-scoped declarations (let, const, function, class) not yet supported outside strict mode', // Facebook browser ~v315
  "Unexpected keyword 'const'. Const declarations are not supported in strict mode.", // Safari ~v8
  'Use of const in strict mode.', // Samsung Internet ~v3.5
  'let is a reserved identifier', // Firefox ~v27
  "Unexpected token 'const'", // Firefox ~v43

  /**
   * These errors are generated by some obscure browser.
   * https://stackoverflow.com/questions/64175183/what-does-these-error-means-getreadmodeconfig-getreadmoderender-getreadmodeext */
  "Cannot read property 'getReadModeRender' of undefined",
  "Cannot read property 'getReadModeExtract' of undefined",
  "Cannot read property 'getReadModeConfig' of undefined",

  /**
   * These errors come from iOS. Without much reason it looks like from this issue
   * https://github.com/getsentry/sentry-javascript/issues/3040 */
  /window.webkit.messageHandlers/,

  /**
   * "Results from navigator.sendBeacon being called out of context"
   * https://stackoverflow.com/questions/67224859/typeerror-illegal-invocation
   * Some more info on why this might be happening within the gtag code: https://xgwang.me/posts/you-may-not-know-beacon/
   * Given that this is a library out of our control, we'll ignore this for now. */
  'Illegal invocation',

  /**
   * These errors only come from Vivo devices which are sold in China. Looks like they
   * come prepared with some extension that looks for these variables on every page. */
  /privateSpecialRepair/,
  /processRandomSelector/,

  /**
   * Fetch failures of chrome extensions (youtube ad skippers)
   */
  /.*jlpjgbpbbcegdepdehainachpmmckppe.*/i,
  /.*jaaddglnfdmndlnomgigbmojbipbjfld.*/i,

  /**
   * Most common CORS block of russian spam websites
   */
  /Failed to execute 'send' on 'XMLHttpRequest'.*/i,

  /**
   * Common network error issue bloat
   */
  /Loading chunk .* failed..*/i,
  /Unexpected end of script/i,
  /Request has been terminated/i,

  /**
   * Injected extension error
   */
  /twttr is not defined/i,

  /**
   * Microsoft plugins
   */
  /MicrosoftAjax.js is not loaded successfully./i,

  /**
   * No associated info, not useful
   */
  /The object can not be found here.*/i,
  /**
   * Common extension/browser errors from: https://gist.github.com/pioug/b006c983538538066ea871d299d8e8bc
   */
  /^No error$/,
  /__show__deepen/,
  /_avast_submit/,
  /anonymous function: captureException/,
  /Blocked a frame with origin/,
  /can't redefine non-configurable property "userAgent"/,
  /change_ua/,
  /console is not defined/,
  /cordova/,
  /DataCloneError/,
  /Error: AccessDeny/,
  /event is not defined/,
  /feedConf/,
  /ibFindAllVideos/,
  /myGloFrameList/,
  /MyIPhoneApp/,
  /snapchat.com/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /window\.regainData/,
  /ztePageScrollModule/,
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Dead_object
  /TypeError: can't access dead object/,
  // For safari interactions with other tabs/pages
  /.*webkit-masked-url:.*/,

  // Ignore issues that contain "Can't find variable: *"/"Can't find variable:*" since they originate from uncommon browsers and not helpful.
  /Can't find variable\: ?OSF/,
  /Can't find variable\: ?eundefined/,
  /Can't find variable\: ?_AutofillCallbackHandler/,
  /Can't find variable\: ?setIOSParameters/,
  /Can't find variable\: ?SymBrowser.*/,
  /Can't find variable\: ?WeixinJSBridge.*/,
  /Can't find variable\: ?instantSearchSDKJSBridgeClearHighlight.*/,
  /**
   * This is probably from a browser extension or integrated view of menti.com
   * looking for the global variable zal  oJSV2. URL always seems to have a URL
   * with query params like this, which could be investigated in more detail.
   *    "?zarsrc=1303&utm_source=zalo&utm_medium=zalo&utm_campaign=zalo" */
  /Can't find variable\: zaloJSV2.*/,
  /zaloJSV2 is not defined/,
  // globalThis and Set is supported in all browser versions we support (https://www.mentimeter.com/requirements#supported-browsers-and-devices)
  /Can't find variable\: ?globalThis/,
  /globalThis is not defined/,
  /Unexpected identifier 'Set'/,

  // This error means that ResizeObserver was not able to deliver all observations within a single animation frame.
  // It is benign (your site will not break). https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  'ResizeObserver loop limit exceeded',

  // This is from on device js injected in iOS Chrome https://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
  /evaluating '__gCrWeb/,

  // OneTrust can raise this issue when you navigate too fast between pages. Ticket in OneTrust doc: https://ideas.onetrust.com/ideas/PDGI-I-1048
  /evaluating 'r\.DomainData/,
  /evaluating 'a\.Domain/,
  /reading 'DomainData/,
  /reading 'Domain/,
  /null is not an object \(evaluating 'this\.selector\.appendChild'\)/,
  /undefined is not an object \(evaluating '_\.bannerGroup\.html'\)/,
  /Cannot read properties of undefined \(reading 'html'\)/,
];

export const commonClientDenyUrls: RegExp[] = [
  ...browserExtensionDenyList,
  /**
   * External scripts we use that we don't want any noise from
   */
  /.*google.*/i,
  /.*facebook.*/i,
  /.*hotjar.*/i,
  /.*intercom.*/i,
  /.*taboola.*/i,
  /.*6sense.*/i,
  /.*6sc.co.*/i,
  /.*licdn.*/i,
  /.*maze.*/i,
];

export const ignoredErrorCodes: number[] = [
  5001, // Office
  90001, // Ably Error: Channel operation failed
  90007, // Ably Error: Channel attach timed out (Possibly due to network issues)
];

export const ignoredErrorMessages: RegExp[] = [
  /^no instance for.*/,
  /.*_tfa.*/,
  /.*AxiosError.*/,
  /.*AppConfig is not defined.*/, //Has no stack trace or helpful information required to resolve it
  /^sap is not defined.*/,
];

export const ignoredErrorNames: RegExp[] = [/^AxiosError$/];

export const ignoredStackTraces: RegExp[] = [
  new RegExp('^chrome-extension://'),
  new RegExp('^webpack://'),
  new RegExp('^://hidden.*'),
];
