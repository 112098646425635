import type { ErrorEvent, EventHint, StackFrame } from '@sentry/types';
import {
  ignoredErrorCodes,
  ignoredErrorMessages,
  ignoredErrorNames,
  ignoredStackTraces,
} from './client-ignore-lists';

let sentryErrors = 0;
const MAX_CLIENT_ERRORS = 50;

const allowStacktraces = [
  new RegExp('./src.*.[jt]sx?'),
  new RegExp('packages.*.[jt]sx?'),
  new RegExp('design.*.[jt]sx?'),
  new RegExp('question-modules.*.[jt]sx?'),
];

declare global {
  interface Window {
    next?: any;
  }
}

/**
 * Interface that extends normal errors with extra attributes found in third party errors
 */
interface ExtendedError extends Error {
  code?: number;
  statusCode?: number;
}

export function beforeSend(
  event: ErrorEvent,
  hint: EventHint,
  originalException: ExtendedError,
): ErrorEvent | null {
  if (event.environment === 'dev') {
    // eslint-disable-next-line no-console
    console.log('Sentry capture exception received:', event, hint);
  }

  /*
   * Ignore errors
   */
  // Ignore repeated errors from the same client
  if (++sentryErrors > MAX_CLIENT_ERRORS) {
    return null;
  }

  // Ignore error from ignoreLists
  if (
    (originalException.code &&
      ignoredErrorCodes.includes(originalException.code)) ||
    (originalException.message &&
      ignoredErrorMessages.some((rx: RegExp) =>
        rx.test(originalException.message),
      )) ||
    (originalException.name &&
      ignoredErrorNames.some((rx: RegExp) => rx.test(originalException.name)))
  ) {
    return null;
  }

  // Ignore errors from local drive
  if (event.request?.url?.startsWith('file://')) {
    return null;
  }

  // Ignore errors without stacktraces
  if (
    event?.exception?.values &&
    event.exception.values[0] &&
    !event.exception.values[0].stacktrace &&
    !isAblyIssue(originalException)
  ) {
    return null;
  }

  // Ignore errors with bad stacktraces
  if (
    event?.exception?.values &&
    event.exception.values[0] &&
    isBadStacktrace(event.exception.values[0].stacktrace?.frames)
  ) {
    return null;
  }

  /*
   * Modify errors
   */
  if (
    isAblyIssue(originalException) &&
    event.exception &&
    event.exception.values &&
    event.exception.values[0]
  ) {
    event.exception.values[0].value = `Ably Error: code ${originalException.code}, statusCode ${originalException.statusCode}, ${originalException.message}`;
  }

  /*
   * Group errors
   */

  return groupExceptions(event, originalException);
}

function groupExceptions(event: ErrorEvent, exception: ExtendedError) {
  const fingerprint: string[] = [];
  fingerprint.push(window.next?.router?.state?.pathname ?? 'no-next-path');
  for (const grouping of issueGrouping) {
    if (grouping.regex.test(exception.message)) {
      fingerprint.push(grouping.fingerprint);
    }
  }
  if (isAblyIssue(exception)) {
    fingerprint.push(`ably-error-${exception.code}`);
  }

  // Only write fingerprint when more than next path is added
  if (fingerprint.length > 1) {
    event.fingerprint = fingerprint;
  }
  return event;
}

const issueGrouping: { regex: RegExp; fingerprint: string }[] = [
  {
    regex: /Invariant\: attempted to hard navigate to the same URL.*/,
    fingerprint: 'hard-navigate-next',
  },
];

function isAblyIssue(exception: ExtendedError): boolean {
  const ablyErrorCodes = [
    10000, 40000, 40001, 40005, 40006, 40006, 40009, 40010, 40012, 40013, 40030,
    40032, 40100, 40101, 40102, 40103, 40104, 40105, 40111, 40112, 40114, 40115,
    40131, 40142, 40143, 40144, 40160, 40161, 40171, 40300, 40330, 40331, 40332,
    40400, 42910, 42911, 50000, 50000, 50001, 50003, 61002, 70002, 80000, 80002,
    80003, 80008, 80016, 80017, 80019, 80021, 80022, 90001, 90007, 90010, 90021,
    91000, 91001, 91005,
  ];
  if (
    exception.code &&
    exception.statusCode &&
    ablyErrorCodes.includes(exception.code)
  ) {
    return true;
  } else {
    return false;
  }
}

function isBadStacktrace(stackFrames: StackFrame[] | undefined): boolean {
  if (!stackFrames) {
    return false;
  }

  //ignore if value is in filename or abspath
  const ignoreIssue = stackFrames.some((frame) =>
    ignoredStackTraces.some(
      (rx) =>
        frame.filename &&
        frame.abs_path &&
        (rx.test(frame.filename) || rx.test(frame.abs_path)),
    ),
  );

  //allow if value exists in filename or abspath
  const allowIssue = stackFrames.some((frame) =>
    allowStacktraces.some(
      (rx) =>
        frame.filename &&
        frame.abs_path &&
        (rx.test(frame.filename) || rx.test(frame.abs_path)),
    ),
  );

  return ignoreIssue && !allowIssue;
}
