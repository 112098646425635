import { beforeSend } from './before-send';
import {
  commonClientDenyUrls,
  commonClientIgnoreErrors,
} from './client-ignore-lists';
import { errorMiddleware } from './error-middleware';

const environmentName = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ?? 'dev';

export const standardSentryConfig = {
  environment: environmentName,
  beforeSend: errorMiddleware(beforeSend),
  tracesSampleRate: 0,
};

export const extraClientConfig = {
  ignoreErrors: [...commonClientIgnoreErrors],
  denyUrls: [...commonClientDenyUrls],
};
